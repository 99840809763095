import React, { Component } from "react"
import Button from "react-bootstrap/Button"
import A from "../images/womensm850.png"

const divStyles = {
  padding: `1rem 3rem`,
}

const imgStyles = {
  margin: `1rem 0 2rem 0`,
  width: `100%`,
}

const headlineStyles = {
  textAlign: `center`,
  fontSize: `2.2rem`,
  fontWeight: `600`,
}

const paraStyles = {
  textAlign: `center`,
  fontSize: `1.25rem`,
  fontWeight: `500`,
  paddingTop: `0.5rem`,
}

export default class CompPicFirst extends Component {
  render() {
    return (
      <div style={divStyles} className="portdivs">
        <img
          src={A}
          alt="portfoliowomensmassage"
          style={imgStyles}
          className="portimgs"
        />
        <div style={{ paddingTop: `1rem` }}>
          <h1 style={headlineStyles}>Customer Website: Women's Massage</h1>
          <p style={paraStyles} className="portdivstext">
            Custom website developed for Women's Massage, a local business
            specializing in women's health and eco friendly products. During the
            consultation process the layout was agreed to with check's at regular
            intervals to ensure the end product is delivered on time and to the
            customer's satisfaction. Tech Specs: Amazon Web Services(AWS): S3
            and AWS Lambda, Payment Gateway Integrated: Stripe, React
            (GatsbyJS), GraphQL
          </p>
        </div>
        <span style={{ display: `grid`, justifyContent: `center` }}>
          <a
            href="http://womensmassage.saganbrainz.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              style={{
                marginBottom: `2.0rem`,
                backgroundColor: `#FF5722`,
                border: `none`,
                fontWeight: `600`,
                height: `3rem`,
                width: `12rem`,
                display: `grid`,
                placeContent: `center`,
              }}
            >
              Visit Website
            </Button>
          </a>
        </span>
      </div>
    )
  }
}
