import React, { Component } from "react"
import EmailShow from "./Emailshow"

const divStyles = {
  padding: `1rem 1rem 2rem 1rem`,
  display: `grid`,
  placeContent: `center`,
  placeItems: `center`,
}

const headlineStyles = {
  textAlign: `center`,
  fontSize: `1.9rem`,
  fontWeight: `500`,
  padding: `1rem 0 0 0`,
}

export default class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      prodCode: "sku_G1VD08s4e8WBga",
    }
  }

  render() {
    return (
      <div style={divStyles}>
        <h1 style={headlineStyles}>Contact</h1>
        <p className="paracontact">
          Please feel free to contact me on my email below, and I will reply as
          soon as possible. Thanks
        </p>{" "}
        <EmailShow />
      </div>
    )
  }
}
