import React from "react"
import Button from "react-bootstrap/Button"

const btnStyles = {
  backgroundColor: `rgb(255, 87, 34)`,
  border: `none`,
  fontWeight: `600`,
}

class EmailShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibility: false,
    }

    this.toggleVisibility = this.toggleVisibility.bind(this)
  }

  toggleVisibility() {
    if (this.state.visibility) {
      this.setState({
        visibility: false,
      })
    } else {
      this.setState({
        visibility: true,
      })
    }
  }

  render() {
    if (this.state.visibility) {
      return (
        <div>
          <a
            href="mailto:sachs@saganbrainz.com?Subject=Hello%20Sach"
            target="_top"
          >
            <p className="email">sachs@saganbrainz.com</p>
          </a>
        </div>
      )
    } else {
      return (
        <div>
          <Button
            onClick={this.toggleVisibility}
            style={btnStyles}
            className="contactbtn"
          >
            Show Email
          </Button>
        </div>
      )
    }
  }
}

export default EmailShow
