import React, { Component } from "react"
import BannerImg from "../images/banner.png"

const divStyles = {
  display: `grid`,
  placeItems: `center`,
  borderBottom: `1px solid lightgrey`,
  padding: `0 0 0rem 0`,
}

const headlineStyles = {
  padding: `2rem 0 1rem 0`,
}

const imgStyles = {
  width: `70vw`,
  borderRadius: `0.5rem`,
  maxWidth: `800px`,
}

export default class Banner extends Component {
  render() {
    return (
      <div style={divStyles}>
        <img src={BannerImg} alt="banner heading" style={imgStyles} />
        <h1 style={headlineStyles}>Sachin Sharma</h1>
      </div>
    )
  }
}
