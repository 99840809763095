import React, { Component } from "react"
import Button from "react-bootstrap/Button"
import A from "../images/eseller1000.png"

const divStyles = {
  padding: `1rem 3rem`,
  background: `#f5f5f5`,
}

const imgStyles = {
  borderRadius: `0.5rem`,
  margin: `2rem 0`,
  width: `100%`,
}

const headlineStyles = {
  textAlign: `center`,
  fontSize: `2.2rem`,
  fontWeight: `600`,
}

const paraStyles = {
  textAlign: `center`,
  fontSize: `1.25rem`,
  fontWeight: `500`,
  paddingTop: `0.5rem`,
}

export default class CompPicFirst extends Component {
  render() {
    return (
      <div style={divStyles} className="portdivs">
        <img
          src={A}
          alt="portfolioeseller"
          style={imgStyles}
          className="portimgs"
        />
        <div style={{ paddingTop: `1rem` }}>
          <h1 style={headlineStyles}>Book Seller</h1>
          <p style={paraStyles} className="portdivstext">
            Book Seller is an e-commerce application, showcasing the combination
            of a React frontend, NodeJS, Express backend, and Stripe for payment
            processing.
          </p>
        </div>
        <span style={{ display: `grid`, justifyContent: `center` }}>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href="https://shrouded-thicket-50015.herokuapp.com/"
          >
            <Button
              style={{
                marginBottom: `2.0rem`,
                backgroundColor: `#FF5722`,
                border: `none`,
                fontWeight: `600`,
                height: `3rem`,
                width: `12rem`,
                display: `grid`,
                placeContent: `center`
              }}
            >
              TRY IT
            </Button>
          </a>
        </span>
      </div>
    )
  }
}
