import React from "react"
import Main from "../components/main"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/static/styles/main.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Main />
  </Layout>
)

export default IndexPage
