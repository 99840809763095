import React, { Component } from "react"
import Button from "react-bootstrap/Button"
import A from "../images/cr.png"

const divStyles = {
  padding: `1rem 3rem`,
}

const imgStyles = {
  margin: `1rem 0 3rem 0`,
  width: `100%`,
}

const headlineStyles = {
  textAlign: `center`,
  fontSize: `2.2rem`,
  fontWeight: `600`,
  paddingTop: `1rem`,
}

const paraStyles = {
  textAlign: `center`,
  fontSize: `1.15rem`,
  fontWeight: `500`,
}

export default class CompParaFirst extends Component {
  render() {
    return (
      <div style={divStyles} className="portdivs">
        <div style={{ paddingTop: `1rem` }}>
          <h1 style={headlineStyles}>Crystals Reiki</h1>
          <p style={paraStyles} className="portdivstext">
            Website produced to allow for efficient browsing of the products and
            to make for a seamless checkout process for clients. Tech Specs:
            ASP.NET Core, SQL, PayPal
          </p>
        </div>
        <img
          src={A}
          alt="portfoliocrystalsreiki"
          style={imgStyles}
          className="portimgs"
        />
        <span style={{ display: `grid`, justifyContent: `center` }}>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href="https://crystalsreiki.com/"
          >
            <Button
              style={{
                marginBottom: `0.0rem`,
                backgroundColor: `#FF5722`,
                border: `none`,
                fontWeight: `600`,
                height: `3rem`,
                width: `12rem`,
                margin: `auto`,
                display: `grid`,
                placeContent: `center`,
              }}
            >
              TRY IT
            </Button>
          </a>
        </span>
      </div>
    )
  }
}
