import React, { Component } from "react"
import CompParaFA from "./CompParaSubZero"
import CompPicF from "./CompPicFirst"
import CompParaFO from "./CompParaFirstZero"
import CompParaF from "./CompParaFirst"
import CompParaF1 from "./CompParaFirstOne"
import CompPicF2 from "./CompPicFirstTwo"
import Banner from "./Banner"
import Contact from "./contact"
import "./static/styles/main.css"

const divStyles = {
  padding: `0`,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  justifyContent: `center`,
}

export default class Main extends Component {
  render() {
    return (
      <div style={divStyles} className="main">
        <Banner />
        <CompParaFA />
        <CompParaFO />
        <CompPicF />
        <CompParaF1 />
        <CompPicF2 />
        <CompParaF />
        <Contact />
      </div>
    )
  }
}
