import React, { Component } from "react"
import Button from "react-bootstrap/Button"
import A from "../images/wy.png"

const divStyles = {
  padding: `1rem 3rem`,
  background: `#f5f5f5`,
}

const imgStyles = {
  margin: `1rem 0 2rem 0`,
  width: `100%`,
}

const headlineStyles = {
  textAlign: `center`,
  fontSize: `2.2rem`,
  fontWeight: `600`,
  paddingTop: `1rem`,
}

const paraStyles = {
  textAlign: `center`,
  fontSize: `1.15rem`,
  fontWeight: `500`,
}

export default class CompParaFirstZero extends Component {
  render() {
    return (
      <div style={divStyles} className="portdivs">
        <div style={{ paddingTop: `1rem` }}>
          <h1 style={headlineStyles}>Wellness Yoga</h1>
          <p style={paraStyles} className="portdivstext">
            This comprehensive, multiple page website initially developed as
            first draft & then polished as a sample-site. The format allows
            plenitude of information dissemination, well suited to a bustling
            firm. Tech Specs: React, GatsbyJS,
            MongoDB, Stripe
          </p>
        </div>
        <img
          src={A}
          alt="wellnessyoga"
          style={imgStyles}
          className="portimgs"
        />
        <span
          style={{
            display: `grid`,
            justifyContent: `center`,
            marginBottom: `2.0rem`,
          }}
        >
          <a
            rel="noreferrer noopener"
            target="_blank"
            href="https://wellness-yoga.vercel.app/"
          >
            <Button
              style={{
                marginBottom: `0.0rem`,
                backgroundColor: `#FF5722`,
                border: `none`,
                fontWeight: `600`,
                height: `3rem`,
                width: `12rem`,
                margin: `auto`,
                display: `grid`,
                placeContent: `center`,
              }}
            >
              TRY IT
            </Button>
          </a>
        </span>
      </div>
    )
  }
}
